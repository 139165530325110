import createAPI from './APIRequest';
import {createKnowledgeBlock} from "./elementCreators/createKnowledgeBlock";
import {createNewsBlock} from "./elementCreators/createNewsBlock";
import {createQandaBlock} from "./elementCreators/createQandaBlock";
import {createRecipeBlock} from "./elementCreators/createRecipeBlock";
import {createVideoBlock} from "./elementCreators/createVideoBlock";
import {createKeywordBlock} from "./elementCreators/createKeywordBlock";
import {createExperienceBlock} from "./elementCreators/createExperienceBlock";
import {createKeywordListBlock} from "./elementCreators/createKeywordListBlock";
import {createFeatureBlock} from "./elementCreators/createFeatureBlock";
import {createQandaListBlock} from "./elementCreators/createQandaListBlock";
import {createDetailKnowledgeBlock} from "./elementCreators/createDetailKnowledgeBlock";
import {createDetailVideoBlock} from "./elementCreators/createDetailVideoBlock";
import {createDetailQandAVideoBlock} from "./elementCreators/createDetailQandAVideoBlock";
import {createTopicArticleBlock} from "./elementCreators/createTopicArticleBlock";
import {createIndexNewsTopBlock} from "./elementCreators/createIndexNewsTopBlock";
import {createIndexNewsMiddleBlock} from "./elementCreators/createIndexNewsMiddleBlock";
import {createIndexNewsSeriesBlock} from "./elementCreators/createIndexNewsSeriesBlock";
import {createIndexNewsSeriesRankingBlock} from "./elementCreators/createIndexNewsSeriesRankingBlock";
import {grid} from "./grid";
import {createPopularNewsRankingBlock} from "./elementCreators/createPopularNewsRankingBlock";
import {createKeywordsNewsKeywordsBlock} from "./elementCreators/createKeywordsNewsKeywordsBlock";
import {createKeywordsNewsSeriesKeywordsBlock} from "./elementCreators/createKeywordsNewsSeriesKeywordsBlock";
import {createAuthorSeriesBlock} from "./elementCreators/createAuthorSeriesBlock";
import Swiper from "swiper";
import {createIndexNewsInformationBlock} from "./elementCreators/createIndexNewsInformationBlock";
import {createNewsSeriesBlock} from "./elementCreators/createNewsSeriesBlock";
import {createNewsSeriesRankingBlock} from "./elementCreators/createNewsSeriesRankingBlock";
import {createNewsClipsBlock} from "./elementCreators/createNewsClipsBlock";
import {createNewsRankingBlock} from "./elementCreators/createNewsRankingBlock";
import {clipNews} from "./clipNews";
import {createTagNewsRankingBlock} from "./elementCreators/createTagNewsRankingBlock";
import {createSeriesKeywordRankingBlock} from "./elementCreators/createSeriesKeywordRankingBlock";
import {setPagination} from "./setPagination";
import {createPickupBlock} from "./elementCreators/createPickupBlock";
import {createIndexNewsSeriesNominationBlock} from "./elementCreators/createIndexNewsSeriesNominationBlock";
import {createTagSeriesRankingBlock} from "./elementCreators/createTagSeriesRankingBlock";
import {createModalNewsArticlesBlock} from "./elementCreators/createModalNewsArticlesBlock";
import {createModalNewsRankingBlock} from "./elementCreators/createModalNewsRankingBlock";
import {createModalSeriesRankingBlock} from "./elementCreators/createModalSeriesRankingBlock";
import {createNewsSeriesTagSelectorBlock} from "./elementCreators/createNewsSeriesTagSelectorBlock";
import {createIndexHistoryBlock} from "./elementCreators/createIndexHistoryBlock";
import {createActiveVotesBlock} from "./elementCreators/createActiveVotesBlock";
import {createInActiveVotesBlock} from "./elementCreators/createInActiveVotesBlock";
import {clipAnswer} from "./clipAnswer";
import {addBallotPercent} from "./newsBallot";
import {readFullComment} from "./readFullComment";
import {clipNewsSeriesKeyword} from "./clipNewsSeriesKeyword";
import {createEmbedTweetBlock} from "./elementCreators/createEmbedTweetBlock";
import {createEmbedTweetSquareBlock} from './elementCreators/createEmbedTweetSquareBlock';
import {createNewsSeriesThemeRankingBlock} from "./elementCreators/createNewsSeriesThemeRankingBlock";


export {searchResources};

function render($viewer, response, options) {
  let $row = null;
  const $hidden = $viewer.querySelectorAll('.qanda');
  options.element === 'qandaList' && ($row = createQandaListBlock(response.data, options));
  if ($row) {
    $hidden[0].appendChild($row);
    $row.dataset.originalSort = $viewer.querySelectorAll('.element-creators-row').length;
  }
  if (options.element === 'knowledge') {
    if (response.data.total_count != 0) {
      $viewer.appendChild(createKnowledgeBlock(response.data, options));
    }else {
      $viewer.parentElement.classList.add('is-hidden');
    }
  }
  if (options.element === 'news') {
    if (response.data.total_count != 0) {
      $viewer.appendChild(createNewsBlock(response.data, options));
    }else {
      $viewer.parentElement.classList.add('is-hidden');
    }
  }
  if (options.element === 'qanda') {
    if (response.data.total_count != 0) {
      $viewer.appendChild(createQandaBlock(response.data, options));
    }else {
      $viewer.parentElement.classList.add('is-hidden');
    }
  }
  if (options.element === 'recipe') {
    if (response.data.total_count != 0) {
      $viewer.appendChild(createRecipeBlock(response.data, options));
    }else {
      $viewer.parentElement.classList.add('is-hidden');
    }
  }
  if (options.element === 'video') {
    if (response.data.total_count != 0) {
      $viewer.appendChild(createVideoBlock(response.data, options));
    }else {
      $viewer.parentElement.classList.add('is-hidden');
    }
  }
  if (options.element === 'keyword') {
    $viewer.appendChild(createKeywordBlock(response.data, options));
  }
  if (options.element === 'experience') {
    $viewer.appendChild(createExperienceBlock(response.data, options));
  }
  if (options.element === 'keywordList') {
    $viewer.appendChild(createKeywordListBlock(response.data, options));
  }
  if ((options.element === 'feature') && (response.data.feature != null)) {
    $viewer.appendChild(createFeatureBlock(response.data, options));
  }
  if (options.element === 'detailVideos') {
    $viewer.appendChild(createDetailVideoBlock(response.data, options));
  }
  if (options.element === 'detailKnowledges') {
    $viewer.appendChild(createDetailKnowledgeBlock(response.data, options));
  }
  if (options.element === 'detailQandAVideo') {
    $viewer.appendChild(createDetailQandAVideoBlock(response.data, options));
  }
  if (options.element === 'topicarticle') {
    $viewer.appendChild(createTopicArticleBlock(response.data, options));
  }
  if (options.element === 'indexNews') {
    if (response.total_count != 0) {
      if($viewer.dataset.resourcesPage && parseInt($viewer.dataset.resourcesPage) === 1){
        const $hidden_top = $viewer.querySelectorAll('.index-news-top');
        if ($hidden_top.length > 0) {
          $hidden_top[0].appendChild(createIndexNewsTopBlock(response.data, options));
        }
      }
      const $hidden_middle = $viewer.querySelectorAll('.index-news-middle');
      $hidden_middle[0].appendChild(createIndexNewsMiddleBlock(response.data, options,$viewer.dataset.resourcesPage,$viewer.dataset.resourcesState));
    }
  }
  if (options.element === 'indexNewsInformation') {
    if (response.total_count != 0) {
      $viewer.appendChild(createIndexNewsInformationBlock(response.data, options));
    }
  }
  if (options.element === 'indexNewsSeries') {
    if (response.total_count != 0) {
      const $hidden = $viewer.querySelectorAll('.index-news-series');
      $hidden[0].appendChild(createIndexNewsSeriesBlock(response.data, options));
    }
  }
  if (options.element === 'indexNewsSeriesNomination') {
    if (response.total_count != 0) {
      const $hidden = $viewer.querySelectorAll('.index-news-series-nomination');
      $hidden[0].appendChild(createIndexNewsSeriesNominationBlock(response.data, options));
    }
  }
  if (options.element === 'indexNewsSeriesRanking') {
    if (response.total_count != 0) {
      $viewer.appendChild(createIndexNewsSeriesRankingBlock(response.data, options));
    }
  }
  if (options.element === 'popularNewsRanking') {
    $viewer.appendChild(createPopularNewsRankingBlock(response.data, options));
    const selectors = '.js-clip-news';
    clipNews(Array.prototype.slice.call(document.querySelectorAll(selectors), 0));
    const selector = '.js-btn-readmore';
    readFullComment(Array.prototype.slice.call(document.querySelectorAll(selector), 0));
  }
  if (options.element === 'newsRanking') {
    $viewer.appendChild(createNewsRankingBlock(response.data, options));
    const selectors = '.js-clip-news';
    clipNews(Array.prototype.slice.call(document.querySelectorAll(selectors), 0));
    const selector = '.js-btn-readmore';
    readFullComment(Array.prototype.slice.call(document.querySelectorAll(selector), 0));
  }
  if (options.element === 'tagNewsRanking') {
    $viewer.appendChild(createTagNewsRankingBlock(response.data, options));
    const selectors = '.js-clip-news';
    clipNews(Array.prototype.slice.call(document.querySelectorAll(selectors), 0));
    const selector = '.js-btn-readmore';
    readFullComment(Array.prototype.slice.call(document.querySelectorAll(selector), 0));
  }
  if (options.element === 'seriesKeywordRanking') {
    $viewer.appendChild(createSeriesKeywordRankingBlock(response.data, options));
    const selectors = '.js-clip-news-series-keyword';
    clipNewsSeriesKeyword(Array.prototype.slice.call(document.querySelectorAll(selectors), 0));
  }
  if (options.element === 'newsClips') {
    if (response.total_count != 0) {
      $viewer.appendChild(createNewsClipsBlock(response.data, options));
    }else{
      $viewer.parentElement.parentElement.classList.add('is-hidden');
    }
  }
  if (options.element === 'keywordsNewsKeywords') {
    if (response.total_count != 0) {
      $viewer.appendChild(createKeywordsNewsKeywordsBlock(response.data, options));
    }
  }
  if (options.element === 'keywordsNewsSeriesKeywords') {
    if (response.total_count != 0) {
      $viewer.appendChild(createKeywordsNewsSeriesKeywordsBlock(response.data, options));
    }
  }
  if (options.element === 'pickup') {
    const $hidden = $viewer.querySelectorAll('.pickupViewer');
    createPickupBlock(response.data, options, $hidden)

    const $id = $viewer.dataset.resourcesState;
    const pickup = new Swiper(".js-pickup-carousel-" + $id, {
      slidesPerView: 'auto',
      centeredSlides: true,
      nested:true,
      loop:true,
      autoHeight: true,
      mousewheel: true,
    })
  }
  if (options.element === 'newsSeriesTagSelector') {
    const $hidden = $viewer.querySelectorAll('.newsSeriesTagSelectorViewer');
    $hidden.forEach($el => {
      createNewsSeriesTagSelectorBlock(response.data, options, $el)
    });
  }
  if (options.element === 'modalNewsArticles') {
    if (response.total_count != 0) {
      $viewer.appendChild(createModalNewsArticlesBlock(response.data, options));
    }
  }
  if (options.element === 'modalNewsRanking') {
    $viewer.appendChild(createModalNewsRankingBlock(response.data, options));
  }
  if (options.element === 'modalSeriesRanking') {
    if (response.total_count != 0) {
      $viewer.appendChild(createModalSeriesRankingBlock(response.data, options));
    }
  }
  if (options.element === 'authorSeries') {
    if (response.total_count != 0) {
      const $hidden = $viewer.querySelectorAll('.authorSeriesViewer');
      createAuthorSeriesBlock(response.data, options, $hidden)

      const swiper_author_series = new Swiper(".js-author-series-carousel", {
        slidesPerView: 'auto',
        nested:true,
        autoHeight: true,
        autoWidth: true,
        mousewheel: true,
        scrollbar: {
          el: '.js-author-series-scrollbar',
          draggable: true,
          // hide: true
        },
      })
    }else{
      $viewer.classList.add('is-hidden');
    }
  }
  if (options.element === 'tagSeriesRanking') {
    if (response.total_count != 0) {
      const $hidden = $viewer.querySelectorAll('.tagSeriesRankingViewer');
      createTagSeriesRankingBlock(response.data, options, $hidden)

      const swiper_tag_series_ranking = new Swiper(".js-tag-series-ranking-carousel", {
        slidesPerView: 'auto',
        nested:true,
        autoHeight: true,
        autoWidth: true,
        mousewheel: true,
        scrollbar: {
          el: '.js-tag-series-ranking-scrollbar',
          draggable: true,
          // hide: true
        },
      })
    }else{
      $viewer.classList.add('is-hidden');
    }
  }
  if (options.element === 'newsSeries') {
    if (response.total_count != 0) {
      const $hidden = $viewer.querySelectorAll('.newsSeriesViewer');
      createNewsSeriesBlock(response.data, options, $hidden)

      //要素追加後だと正常に動く
      const $id = $viewer.dataset.resourcesState;
      const swiper_newsseries = new Swiper(".js-series-carousel-" + $id, {
        slidesPerView: 'auto',
        nested:true,
        autoHeight: true,
        autoWidth: true,
        mousewheel: true,
        scrollbar: {
          el: '.js-series-scrollbar-' + $id,
          draggable: true,
          // hide: true
        },
      })
    }else{
      $viewer.classList.add('is-hidden');
    }
  }
  if (options.element === 'newsSeriesRanking') {
    if (response.total_count != 0) {
      const $hidden = $viewer.querySelectorAll('.newsSeriesRankingViewer');
      createNewsSeriesRankingBlock(response.data, options, $hidden)

      //要素追加後だと正常に動く
      const $id = $viewer.dataset.resourcesState;
      const swiper_newsseries_ranking = new Swiper(".js-series-ranking-carousel-" + $id, {
        slidesPerView: 'auto',
        nested:true,
        autoHeight: true,
        autoWidth: true,
        mousewheel: true,
        scrollbar: {
          el: '.js-series-ranking-scrollbar-' + $id,
          draggable: true,
          // hide: true
        },
      })
    }else{
      $viewer.classList.add('is-hidden');
    }
  }
  if (options.element === 'indexHistory') {
    if (response.total_count != 0) {
      $viewer.appendChild(createIndexHistoryBlock(response.data, options,$viewer.dataset.resourcesPage));
      const selectors = '.js-clip-news';
      clipNews(Array.prototype.slice.call(document.querySelectorAll(selectors), 0));
    }
  }
  if (options.element === 'activeVotes') {
    $viewer.appendChild(createActiveVotesBlock(response.data, options));
    addBallotPercent();
    const selectors = '.js-clip-answer';
    clipAnswer(Array.prototype.slice.call(document.querySelectorAll(selectors), 0));
  }
  if (options.element === 'inactiveVotes') {
    $viewer.appendChild(createInActiveVotesBlock(response.data, options));
    addBallotPercent();
  }
  if (options.element === 'embedTweet') {
    $viewer.appendChild(createEmbedTweetBlock(response.data, options));
  }
  if (options.element === 'embedTweetSquare') {
    $viewer.appendChild(createEmbedTweetSquareBlock(response.data, options));
  }
  if (options.element === 'indexNewsSeriesThemeRanking') {
    $viewer.appendChild(createNewsSeriesThemeRankingBlock(response.data.ranking, options));
  }
  if (options.element === 'indexNewsSeriesTheme') {
    $viewer.appendChild(createNewsSeriesThemeRankingBlock(response.data.news_series, options));
  }
  grid();
}

function _display(el, total_count, options) {
  const $loading = el.querySelectorAll('.is-loading');
  const $gtmNextPage = el.querySelector('.gtm-next-page'); // Google Tag Manager もっと見るPV計測用エレメント
  const $hidden = el.querySelectorAll('.is-hidden');
  $hidden.forEach($h => {
    $h.classList.remove('is-hidden');
  });
  if(options.element === 'indexNews'){
    const offset = 10;
    const perPage = 15;
    const total_show = offset + (parseInt(el.dataset.resourcesPage) - 1) * perPage;
    if (total_show >= total_count || (el.dataset.resourcesCategory === 'all' && total_show >= parseInt(el.dataset.resourcesShowlimit))) {
      $loading.forEach($l => {
        $l.classList.add('is-hidden');
      });
    }else if (total_show >= parseInt(el.dataset.resourcesShowlimit)){

      $loading.forEach($l => {
        $l.classList.add('is-hidden');
      });

      const $a = document.createElement('a');
      $a.classList.add('button', 'is-fullsize');
      $a.href = '/smilenews/' + el.dataset.resourcesCategory + '/all';
      if(el.dataset.resourcesName){
        $a.textContent = el.dataset.resourcesName + '一覧へ';
      }else{
        $a.textContent = '記事一覧へ';
      }
      {
        const $span = document.createElement('span');
        $span.classList.add('babysymbol', 'babysymbol-arrowright', 'fz-i','c-pink','ml10');
        $a.appendChild($span);
      }
      el.appendChild($a);
    }else{
      el.dataset.resourcesPage = (parseInt(el.dataset.resourcesPage) + 1) + '';
      if(parseInt(el.dataset.resourcesPage) >= 2){
        el.dataset.resourcesLimit = '15';
        el.dataset.resourcesOffset = (10 + 15 * (parseInt(el.dataset.resourcesPage) -2)) + '';
      }
      $gtmNextPage && ($gtmNextPage.href = location.pathname + '/more-' + el.dataset.resourcesPage);
    }
  }else if(options.element === 'indexNewsSeries') {
    const offset = 30;
    const perPage = 18;
    const total_show = offset + (parseInt(el.dataset.resourcesPage) - 1) * perPage;
    if (total_show >= total_count) {
      $gtmNextPage && $gtmNextPage.classList.add('is-hidden');
    } else if (total_show >= parseInt(el.dataset.resourcesShowlimit)) {
      $gtmNextPage && $gtmNextPage.classList.add('is-hidden');

      const $a = document.createElement('a');
      $a.classList.add('button', 'is-fullsize');
      $a.href = '/smilenews/newsseries/all';
      $a.textContent = '連載一覧へ';
      {
        const $span = document.createElement('span');
        $span.classList.add('babysymbol', 'babysymbol-arrowright', 'fz-i', 'ml10');
        $a.appendChild($span);
      }
      el.appendChild($a);
    } else {
      el.dataset.resourcesPage = (parseInt(el.dataset.resourcesPage) + 1) + '';
      if (parseInt(el.dataset.resourcesPage) >= 2) {
        el.dataset.resourcesLimit = '18';
        el.dataset.resourcesOffset = (30 + 18 * (parseInt(el.dataset.resourcesPage) - 2)) + '';
      }
      $gtmNextPage && ($gtmNextPage.href = location.pathname + '/more-' + el.dataset.resourcesPage);
    }
  }else if(options.element === 'indexNewsSeriesNomination'){
      const offset = 30;
      const perPage = 18;
      const total_show = offset + (parseInt(el.dataset.resourcesPage) - 1) * perPage;
      if (total_show >= total_count) {
        $loading.forEach($l => {
          $l.classList.add('is-hidden');
        });
      }else{
        el.dataset.resourcesPage = (parseInt(el.dataset.resourcesPage) + 1) + '';
        if(parseInt(el.dataset.resourcesPage) >= 2){
          el.dataset.resourcesLimit = '18';
          el.dataset.resourcesOffset = (30 + 18 * (parseInt(el.dataset.resourcesPage) -2)) + '';
        }
        $gtmNextPage && ($gtmNextPage.href = '/smilenews/newsseries/nomination' + '/more-' + el.dataset.resourcesPage);
      }
  } else{
    if (parseInt(el.dataset.resourcesPage) * parseInt(el.dataset.resourcesLimit) >= total_count) {
      $loading.forEach($l => {
        $l.classList.add('is-hidden');
      });
    } else {
      el.dataset.resourcesPage = (parseInt(el.dataset.resourcesPage) + 1) + '';
      $gtmNextPage && ($gtmNextPage.href = location.pathname + '/more-' + el.dataset.resourcesPage);
    }
  }

  $loading.forEach($l => {
    $l.classList.remove('is-loading');
    $l.style.backgroundColor = null;
    $l.style.borderStyle = null;
    $l.style.height = null;
  });
  ($loading.length == 2) && $loading[0].remove();
  el.querySelector('.get-contents') && el.querySelector('.get-contents').classList.add('is-hidden');
}

function display(el, total_count, options) {
  if (el.dataset.displaySelector) {
    return document.querySelectorAll(el.dataset.displaySelector).forEach($d => _display($d, total_count, options));
  }
  _display(el, total_count, options);
}

function success(el, response) {
  let total_count = 0;
  if(response.data.total_count){
    total_count = response.data.total_count;
  }else if(response.total_count){
    total_count = response.total_count;
  }
  render(el, response, JSON.parse(el.dataset.renderOptions));
  display(el, total_count, JSON.parse(el.dataset.renderOptions));
}

const resourcesUrlCallCounts = {};
function _searchResources(el) {
  let resourcesUrl = el.dataset.resourcesUrl;
  if (el.dataset.resourcesLimit && el.dataset.resourcesPage) {
    resourcesUrl = el.dataset.resourcesUrl + '&limit=' + el.dataset.resourcesLimit + '&page=' + el.dataset.resourcesPage;
  }
  if (el.dataset.resourcesLimit && el.dataset.resourcesOffset) {
    resourcesUrl = el.dataset.resourcesUrl + '&limit=' + el.dataset.resourcesLimit + '&offset=' + el.dataset.resourcesOffset;
  }
  resourcesUrlCallCounts[resourcesUrl] = resourcesUrlCallCounts[resourcesUrl] || 0;
  if (resourcesUrlCallCounts[resourcesUrl]++ > 0 && (!resourcesUrl.includes('knowledge')) && (!resourcesUrl.includes('news')) && (!resourcesUrl.includes('site-options')) ) {
    return;
  }

  createAPI().send(
    resourcesUrl,
    el.dataset.resourcesMethod,
    el.dataset.resourcesAuth,
    el.dataset.resourcesBody,
    function() {
      el.querySelector('.get-contents') && el.querySelector('.get-contents').classList.remove('is-hidden');
      el.querySelector('.more-search-results') && el.querySelector('.more-search-results').classList.add('is-loading');
    },
    function (response) {
      if (el.dataset.resourcesCache) {
        // sessionStorage を使ったキャッシュライト
        const storageId = el.id || el.dataset.storageId;
        const newCache = {
          limit: el.dataset.resourcesLimit,
          page: el.dataset.resourcesPage,
          response: { data: response.data },
        };
        if (newCache.page > 1 && sessionStorage.getItem(storageId)) { // 2ページ目以降だったら追加
          const cache = JSON.parse(decodeURIComponent(sessionStorage.getItem(storageId)));
          cache.response.data.qanda.push(...newCache.response.data.qanda);
          newCache.response.data = cache.response.data;
        }
        sessionStorage.setItem(storageId, encodeURIComponent(JSON.stringify(newCache)));

        let count = response.data.total_count;
        const recordCountId = el.dataset.resourcesState;
        if (sessionStorage.getItem(recordCountId)) {
          count += JSON.parse(decodeURIComponent(sessionStorage.getItem(recordCountId))).response.total_count;
        }
        const countCache = {
          response: { total_count : count },
        };
        sessionStorage.setItem(recordCountId, encodeURIComponent(JSON.stringify(countCache)));

        if (recordCountId  == 'topic-prepregnancy') {
          const setCount = JSON.parse(decodeURIComponent(sessionStorage.getItem(recordCountId))).response.total_count;
          document.querySelector('.js-prepregnancy-count').textContent = setCount +'件';
        }
        if (recordCountId  == 'topic-pregnancy') {
          const setCount = JSON.parse(decodeURIComponent(sessionStorage.getItem(recordCountId))).response.total_count;
          document.querySelector('.js-pregnancy-count').textContent = setCount +'件';
        }
        if (recordCountId  == 'topic-birth') {
          const setCount = JSON.parse(decodeURIComponent(sessionStorage.getItem(recordCountId))).response.total_count;
          document.querySelector('.js-birth-count').textContent = setCount +'件';
        }
        if (recordCountId  == 'topic-baby') {
          const setCount = JSON.parse(decodeURIComponent(sessionStorage.getItem(recordCountId))).response.total_count;
          document.querySelector('.js-baby-count').textContent = setCount +'件';
        }
        if (recordCountId  == 'topic-infertility') {
          const setCount = JSON.parse(decodeURIComponent(sessionStorage.getItem(recordCountId))).response.total_count;
          document.querySelector('.js-infertility-count').textContent = setCount +'件';
        }
      }
      success(el, response);
    }
  );
}

function clickMore(event,el){
  event.preventDefault();
  event.currentTarget.blur();
  _searchResources(el);
}

async function searchResources(elements) {
  const lazy = {};
  if (elements.length > 0) {
    elements.forEach(el => {
      const $more = el.querySelector('.more-search-results');
      $more && $more.addEventListener('click', (event) => clickMore(event,el));
      // sessionStorage を使ったキャッシュリード
      const storageId = el.id || el.dataset.storageId;
      if (
        // キャッシュ 設定が有効
        el.dataset.resourcesCache &&
        // 以前表示したことがある
        (!el.dataset.resourcesState || document.body.dataset.state === JSON.stringify({value: el.dataset.resourcesState})) &&
        // ブラウザバック実行済みではない（safari用）
        !sessionStorage.getItem('popstate') &&
        // ロード済みページが現在のページではない（リロードにはキャッシュを使用しない）
        (!sessionStorage.getItem('load') || sessionStorage.getItem('load') !== location.pathname) &&
        // キャッシュがある
        sessionStorage.getItem(storageId)
      ) {
        console.log(storageId + ' are loaded from cache.');
        const cache = JSON.parse(decodeURIComponent(sessionStorage.getItem(storageId)));
        el.dataset.resourcesLimit = cache.limit;
        el.dataset.resourcesPage = cache.page;
        const state = el.dataset.resourcesState;
        if ( state  == 'topic-prepregnancy' || state  == 'topic-pregnancy'
          || state  == 'topic-birth' || state  == 'topic-baby' || state  == 'topic-infertility'
        ) {
          _checkRecordCount(el);
        }
        success(el, cache.response);
      } else if (el.classList.contains('js-member-only') && document.cookie.replace(/(?:(?:^|.*;\s*)token\s*\=\s*([^;]*).*$)|^.*$/, '$1').length <= 0) {
        // ログインしていない場合は実行不要
      } else {
        const reg = new RegExp(el.dataset.resourcesState ? '/' + el.dataset.resourcesState + '$' : location.pathname);
        if (location.pathname.match(reg)) {
          // 現在表示中
          sessionStorage.getItem('popstate') && sessionStorage.removeItem('popstate'); // ブラウザバック実行済み判定のクリア（safari用）
          const recordCountId = el.dataset.resourcesState;
          if ( recordCountId  == 'topic-prepregnancy' || recordCountId  == 'topic-pregnancy'
            || recordCountId  == 'topic-birth' || recordCountId  == 'topic-baby' || recordCountId  == 'topic-infertility'
          ) {
            _removeRecordCountCache(el);
          }
          _searchResources(el); // 直ちに実行
        } else {
          // 現在非表示のタブ
          if (!(el.dataset.resourcesState in lazy)) {
            lazy[el.dataset.resourcesState] = [];
          }
          lazy[el.dataset.resourcesState].push(() => _searchResources(el));
        }
      }
    });
  }
  return lazy;
}

function _checkRecordCount(el){
  const recordCountId = el.dataset.resourcesState;
  if (recordCountId  == 'topic-prepregnancy' && sessionStorage.getItem(recordCountId)) {
    const setCount = JSON.parse(decodeURIComponent(sessionStorage.getItem(recordCountId))).response.total_count;
    document.querySelector('.js-prepregnancy-count').textContent = setCount +'件';
    sessionStorage.removeItem('topic-pregnancy');
    sessionStorage.removeItem('topic-birth');
    sessionStorage.removeItem('topic-baby');
    sessionStorage.removeItem('topic-infertility');
  }
  if (recordCountId  == 'topic-pregnancy' && sessionStorage.getItem(recordCountId)) {
    const setCount = JSON.parse(decodeURIComponent(sessionStorage.getItem(recordCountId))).response.total_count;
    document.querySelector('.js-pregnancy-count').textContent = setCount +'件';
    sessionStorage.removeItem('topic-prepregnancy');
    sessionStorage.removeItem('topic-birth');
    sessionStorage.removeItem('topic-baby');
    sessionStorage.removeItem('topic-infertility');
  }
  if (recordCountId  == 'topic-birth' && sessionStorage.getItem(recordCountId)) {
    const setCount = JSON.parse(decodeURIComponent(sessionStorage.getItem(recordCountId))).response.total_count;
    document.querySelector('.js-birth-count').textContent = setCount +'件';
    sessionStorage.removeItem('topic-prepregnancy');
    sessionStorage.removeItem('topic-pregnancy');
    sessionStorage.removeItem('topic-baby');
    sessionStorage.removeItem('topic-infertility');
  }
  if (recordCountId  == 'topic-baby' && sessionStorage.getItem(recordCountId)) {
    const setCount = JSON.parse(decodeURIComponent(sessionStorage.getItem(recordCountId))).response.total_count;
    document.querySelector('.js-baby-count').textContent = setCount +'件';
    sessionStorage.removeItem('topic-prepregnancy');
    sessionStorage.removeItem('topic-pregnancy');
    sessionStorage.removeItem('topic-birth');
    sessionStorage.removeItem('topic-infertility');
  }
  if (recordCountId  == 'topic-infertility' && sessionStorage.getItem(recordCountId)) {
    const setCount = JSON.parse(decodeURIComponent(sessionStorage.getItem(recordCountId))).response.total_count;
    document.querySelector('.js-infertility-count').textContent = setCount +'件';
    sessionStorage.removeItem('topic-prepregnancy');
    sessionStorage.removeItem('topic-pregnancy');
    sessionStorage.removeItem('topic-baby');
    sessionStorage.removeItem('topic-birth');
  }
}
function _removeRecordCountCache(el){
    if(sessionStorage.getItem('topic-prepregnancy')) {
      sessionStorage.removeItem('topic-prepregnancy');
    }

    if(sessionStorage.getItem('topic-pregnancy')) {
      sessionStorage.removeItem('topic-pregnancy');
    }

    if(sessionStorage.getItem('topic-birth')) {
      sessionStorage.removeItem('topic-birth');
    }

    if(sessionStorage.getItem('topic-baby')) {
      sessionStorage.removeItem('topic-baby');
    }

    if(sessionStorage.getItem('topic-infertility')) {
      sessionStorage.removeItem('topic-infertility');
    }
}
